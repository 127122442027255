import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Zoho",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/zoho.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Zoho`}</h1>
    <p>{`Sync your Crisp CRM with Zoho`}</p>
    <hr></hr>
    <p>{`Your company is using Zoho CRM to manage all your leads and contacts? Here is your solution. This integration automatically sends contact added on Crisp to Zoho CRM so you don't have to manually sync users`}</p>
    <p>{`Benefits of using Crisp with ZohoCRM:`}</p>
    <ul>
      <li parentName="ul">{`Auto syncs contacts added in Crisp on Zoho`}</li>
      <li parentName="ul">{`Your sales are now able to retarget your Crisp leads using their favorite tool`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      